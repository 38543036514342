<template>
  <v-row no-gutters>
    <v-col :md="md" :sm="sm" :lg="lg">
      <text-field v-bind="$attrs" v-on="$listeners">
          <slot v-for="slot in Object.keys($slots)" :name="slot" :slot="slot"/>
      </text-field>
    </v-col>
  </v-row>
</template>

<script>
import TextField from "components/form/TextField";

export default {
    name: "TextFieldRow",
    props: ["md", "lg", "sm"],
    components: {
        TextField,
    }
}
</script>

<style scoped>

</style>
