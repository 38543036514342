var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "fill-height",
      staticStyle: { "background-color": "#f9f9f9" },
    },
    [
      _c(
        "ContextTitle",
        {
          attrs: {
            passedActions: _vm.titleBarActions,
            title: "Edit Message template",
          },
        },
        [
          _c(
            "div",
            { attrs: { slot: "append-buttons" }, slot: "append-buttons" },
            [
              _c("env-selector-dropdown", {
                model: {
                  value: _vm.environment,
                  callback: function ($$v) {
                    _vm.environment = $$v
                  },
                  expression: "environment",
                },
              }),
            ],
            1
          ),
        ]
      ),
      _c("div", { staticClass: "edit-bar" }),
      _vm.isMessageTemplateLoading
        ? _c(
            "div",
            [
              _c("v-skeleton-loader", {
                attrs: { type: "chip, article, article" },
              }),
            ],
            1
          )
        : _c("div", { staticClass: "message-edit-wrapper" }, [
            _c("div", { staticClass: "message-edit-waypoint" }, [
              _c("div", { staticClass: "message-edit-waypoint__title" }, [
                _vm._v(" " + _vm._s(_vm.waypointLabel) + " "),
              ]),
            ]),
            _c("div", { staticClass: "message-edit-header" }, [
              _c(
                "div",
                {
                  staticClass:
                    "d-flex pt-2 pl-5 pb-2 bottom-separator justify-space-between align-center",
                },
                [
                  _c(
                    "v-chip",
                    {
                      staticStyle: {
                        padding: "0 3px",
                        "text-transform": "uppercase",
                      },
                      attrs: {
                        outlined: "",
                        label: "",
                        color: "#e040fb",
                        small: "",
                      },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.eventLabelById(_vm.messageTemplate.EventTypeID)
                          ) +
                          " "
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { text: "", color: "#93BD20" },
                          on: {
                            click: function ($event) {
                              return _vm.openDuplicateDialog()
                            },
                          },
                        },
                        [_vm._v("Duplicate")]
                      ),
                      _c(
                        "v-btn",
                        { attrs: { text: "", color: "#ccc" } },
                        [_c("v-icon", [_vm._v("delete")])],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("div", { staticClass: "d-flex pb-1 pt-5" }, [
                _c(
                  "div",
                  { staticClass: "message-edit-header__subtitle pl-5" },
                  [_vm._v("Platforms")]
                ),
                _c(
                  "div",
                  { staticClass: "message-edit-header__chips" },
                  _vm._l(_vm.platformTypeOptions, function (platform) {
                    return _c("platform-label", {
                      key: platform.id,
                      attrs: {
                        id: platform.id,
                        value: platform.text,
                        selected: platform.isSelected,
                      },
                    })
                  }),
                  1
                ),
              ]),
              _c("div", { staticClass: "d-flex pb-3 pt-1" }, [
                _c(
                  "div",
                  { staticClass: "message-edit-header__subtitle pl-5" },
                  [_vm._v("Order type")]
                ),
                _c(
                  "div",
                  { staticClass: "message-edit-header__chips" },
                  _vm._l(_vm.orderTypeOptions, function (orderType) {
                    return _c(
                      "v-chip",
                      {
                        key: orderType.id,
                        staticClass: "pl-1 pr-1 ml-1",
                        attrs: {
                          color: orderType.isSelected ? "#679436" : "#ccc",
                          label: "",
                          small: "",
                          outlined: "",
                        },
                      },
                      [_vm._v(" " + _vm._s(orderType.text) + " ")]
                    )
                  }),
                  1
                ),
              ]),
            ]),
            _c(
              "div",
              { staticClass: "editor" },
              [
                _c("div", { staticClass: "editor__header bottom-separator" }, [
                  _c(
                    "div",
                    [
                      _c(
                        "v-icon",
                        { staticClass: "pr-1", attrs: { small: "" } },
                        [_vm._v("message")]
                      ),
                      _vm._v(" Edit this message"),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { text: "", color: "#93BD20" },
                          on: {
                            click: function ($event) {
                              _vm.resetDialogVisibility = true
                            },
                          },
                        },
                        [
                          _vm._v("Reset to default "),
                          _c(
                            "v-icon",
                            { staticClass: "pl-1", attrs: { small: "" } },
                            [_vm._v("undo")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
                _c(
                  "div",
                  { staticClass: "editor__auto-translation bottom-separator" },
                  [
                    _c(
                      "v-btn",
                      {
                        staticClass: "ml-1",
                        attrs: { small: "", text: "", color: "#93BD20" },
                        on: {
                          click: function ($event) {
                            return _vm.automaticTranslation()
                          },
                        },
                      },
                      [
                        _vm._v(" Automatic translation "),
                        _c("v-icon", { attrs: { small: "" } }, [
                          _vm._v("language"),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "v-row",
                  {
                    staticClass: "editor__row d-flex",
                    attrs: { "no-gutters": "" },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "editor__language pt-5 pl-5" },
                      [
                        _c(
                          "v-chip",
                          {
                            staticClass: "pl-1 pr-1 pt-0 pb-0 text-uppercase",
                            attrs: {
                              small: "",
                              dense: "",
                              label: "",
                              dark: "",
                              color: "#439ED5",
                            },
                          },
                          [_vm._v(_vm._s(_vm.languageDisplay))]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { staticClass: "editor__input pr-5" },
                      [
                        _c("MessagingTemplateForm", {
                          attrs: {
                            messageTemplate: _vm.messageTemplate,
                            placeholders: _vm.templateTags,
                          },
                          on: {
                            templateChange: function ($event) {
                              _vm.touched = true
                            },
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
      _c(
        "v-dialog",
        {
          attrs: { width: "400px", disabled: _vm.isMessageTemplateLoading },
          model: {
            value: _vm.resetDialogVisibility,
            callback: function ($$v) {
              _vm.resetDialogVisibility = $$v
            },
            expression: "resetDialogVisibility",
          },
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", { staticClass: "text-h5" }, [
                _vm._v(" Attention "),
              ]),
              _c("v-card-text", [
                _vm._v(" This will overwrite any previously saved messages. "),
              ]),
              _c(
                "v-card-actions",
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: { color: "darken-1", text: "", block: "" },
                              on: {
                                click: function ($event) {
                                  _vm.resetDialogVisibility = false
                                },
                              },
                            },
                            [_vm._v(" Cancel ")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                color: "green darken-1",
                                text: "",
                                block: "",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.resetToDefault()
                                },
                              },
                            },
                            [_vm._v(" Continue ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { width: "400px", disabled: _vm.isMessageTemplateLoading },
          model: {
            value: _vm.duplicateSaveAttentionDialogVisibility,
            callback: function ($$v) {
              _vm.duplicateSaveAttentionDialogVisibility = $$v
            },
            expression: "duplicateSaveAttentionDialogVisibility",
          },
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", { staticClass: "text-h5" }, [
                _vm._v(" Attention "),
              ]),
              _c("v-card-text", [
                _vm._v(" Message must be saved before duplication. "),
              ]),
              _c(
                "v-card-actions",
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: { color: "darken-1", text: "", block: "" },
                              on: {
                                click: function ($event) {
                                  _vm.duplicateSaveAttentionDialogVisibility = false
                                },
                              },
                            },
                            [_vm._v(" Cancel ")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                color: "green darken-1",
                                text: "",
                                block: "",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.saveAndDuplicate()
                                },
                              },
                            },
                            [_vm._v(" Continue ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { width: "800px" },
          model: {
            value: _vm.duplicateDialogVisibility,
            callback: function ($$v) {
              _vm.duplicateDialogVisibility = $$v
            },
            expression: "duplicateDialogVisibility",
          },
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", [_vm._v("Duplicate to another location")]),
              _c("v-card-subtitle", [
                _vm._v("Search for locations, then select to add."),
              ]),
              _c("v-card-text", [
                _c(
                  "div",
                  { staticClass: "d-flex align-center" },
                  [
                    _c("store-search-new", {
                      attrs: {
                        airport: _vm.duplicateDialog.currentAirport,
                        waypoint: _vm.duplicateDialog.currentWaypoint,
                      },
                      on: {
                        "airport-change": function ($event) {
                          _vm.duplicateDialog.currentAirport = $event
                        },
                        "waypoint-change": function ($event) {
                          _vm.duplicateDialog.currentWaypoint = $event
                        },
                      },
                    }),
                    _c(
                      "v-btn",
                      {
                        staticClass: "ml-3",
                        attrs: {
                          disabled: !_vm.duplicateDialog.currentWaypoint,
                          small: "",
                          color: "primary",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.addWaypointToDuplicate()
                          },
                        },
                      },
                      [_vm._v(" Add ")]
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "mt-5" },
                  [
                    _c(
                      "line-item",
                      { staticClass: "pa-5" },
                      [
                        _c(
                          "v-expansion-panels",
                          {
                            model: {
                              value: _vm.duplicateDialog.selectedItemsExpanded,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.duplicateDialog,
                                  "selectedItemsExpanded",
                                  $$v
                                )
                              },
                              expression:
                                "duplicateDialog.selectedItemsExpanded",
                            },
                          },
                          [
                            _c(
                              "v-expansion-panel",
                              [
                                _c("v-expansion-panel-header", [
                                  _c(
                                    "span",
                                    [
                                      _vm._v("Selected waypoints "),
                                      _c(
                                        "v-chip",
                                        {
                                          attrs: {
                                            small: "",
                                            color: "secondary",
                                            outlined: "",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.duplicateDialog.items.length
                                            )
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                                _c(
                                  "v-expansion-panel-content",
                                  [
                                    _c(
                                      "v-list",
                                      _vm._l(
                                        _vm.duplicateDialog.items,
                                        function (waypoint) {
                                          return _c("v-list-item", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.waypointLabelById(
                                                    waypoint
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ])
                                        }
                                      ),
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _c(
                "v-card-actions",
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: { color: "darken-1", text: "", block: "" },
                              on: {
                                click: function ($event) {
                                  return _vm.cancelDuplicate()
                                },
                              },
                            },
                            [_vm._v(" Cancel ")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                color: "green darken-1",
                                text: "",
                                disabled:
                                  _vm.duplicateDialog.items.length === 0,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.duplicate()
                                },
                              },
                            },
                            [_vm._v(" Duplicate to selected waypoints ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { width: "400px" },
          model: {
            value: _vm.duplicateSuccessfullyDialog.visibility,
            callback: function ($$v) {
              _vm.$set(_vm.duplicateSuccessfullyDialog, "visibility", $$v)
            },
            expression: "duplicateSuccessfullyDialog.visibility",
          },
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", [_vm._v("Success!")]),
              _c("v-card-subtitle", [_vm._v("Duplicated to these locations:")]),
              _c(
                "v-card-text",
                [
                  _c(
                    "v-list",
                    _vm._l(
                      _vm.duplicateSuccessfullyDialog.items,
                      function (waypoint) {
                        return _c("v-list-item", [
                          _c(
                            "div",
                            {
                              staticClass:
                                "d-flex justify-space-between align-center",
                              staticStyle: { width: "100%" },
                            },
                            [
                              _c("div", [
                                _vm._v(_vm._s(_vm.waypointLabelById(waypoint))),
                              ]),
                              _c(
                                "div",
                                [
                                  _c(
                                    "v-icon",
                                    { attrs: { color: "primary" } },
                                    [_vm._v("check")]
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                        ])
                      }
                    ),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }