<template>
  <v-text-field
      color="#93BD20"
      v-bind="$attrs"
      v-on="$listeners"
  >
      <slot v-for="slot in Object.keys($slots)" :name="slot" :slot="slot"/>
  </v-text-field>
</template>

<script>
export default {
    name: "TextField",
    props: [
    ],
}
</script>

<style scoped>

</style>
