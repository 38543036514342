var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-text-field",
    _vm._g(
      _vm._b(
        { attrs: { color: "#93BD20" } },
        "v-text-field",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    ),
    [
      _vm._l(Object.keys(_vm.$slots), function (slot) {
        return _vm._t(slot, null, { slot: slot })
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }