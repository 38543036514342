var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-form", { staticClass: "template-form fill-height pt-2" }, [
    _c(
      "div",
      { staticClass: "form-input large block-xs--xs flex-grow-0" },
      [
        _c("template-editor", {
          attrs: { label: "Template", placeholders: _vm.placeholders },
          on: {
            input: function ($event) {
              return _vm.$emit("templateChange")
            },
          },
          model: {
            value: _vm.messageTemplate.MessageBodyTemplate,
            callback: function ($$v) {
              _vm.$set(_vm.messageTemplate, "MessageBodyTemplate", $$v)
            },
            expression: "messageTemplate.MessageBodyTemplate",
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }