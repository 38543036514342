<template>
    <div class="fill-height" style="background-color: #f9f9f9;">
        <ContextTitle :passedActions="titleBarActions" title="Edit Message template">
            <div slot="append-buttons">
                <env-selector-dropdown v-model="environment"/>
            </div>
        </ContextTitle>
        <div class='edit-bar' />
        <div v-if="isMessageTemplateLoading">
            <v-skeleton-loader
                type="chip, article, article"
            />
        </div>
        <div v-else class="message-edit-wrapper">

            <div class="message-edit-waypoint">
                <div class="message-edit-waypoint__title">
                    {{waypointLabel}}
                </div>
            </div>
            <div class="message-edit-header">
                <div class="d-flex pt-2 pl-5 pb-2 bottom-separator justify-space-between align-center">
                    <v-chip
                        outlined
                        label
                        color='#e040fb'
                        small
                        style='padding: 0 3px; text-transform: uppercase'
                    >
                        {{ eventLabelById(messageTemplate.EventTypeID) }}
                    </v-chip>
                    <div>
                        <v-btn @click='openDuplicateDialog()' text color='#93BD20'>Duplicate</v-btn>
                        <v-btn text color='#ccc'><v-icon>delete</v-icon></v-btn>
                    </div>
                </div>
                <div class="d-flex pb-1 pt-5">
                    <div class="message-edit-header__subtitle pl-5">Platforms</div>
                    <div class="message-edit-header__chips">
                        <platform-label
                            v-for="platform of platformTypeOptions"
                            :key="platform.id"
                            :id='platform.id'
                            :value='platform.text'
                            :selected='platform.isSelected'
                        />
                    </div>
                </div>
                <div class="d-flex pb-3 pt-1">
                    <div class="message-edit-header__subtitle pl-5">Order type</div>
                    <div class="message-edit-header__chips">
                        <v-chip
                            v-for="orderType of orderTypeOptions"
                            :key="orderType.id"
                            :color="orderType.isSelected ? '#679436' : '#ccc'"
                            class="pl-1 pr-1 ml-1"
                            label
                            small
                            outlined
                        >
                            {{orderType.text}}
                        </v-chip>
                    </div>
                </div>
            </div>
            <div class="editor">
                <div class="editor__header bottom-separator">
                    <div><v-icon small class='pr-1'>message</v-icon> Edit this message</div>
                    <div>
                        <v-btn @click='resetDialogVisibility=true' text color='#93BD20'>Reset to default <v-icon small class='pl-1'>undo</v-icon></v-btn>
                    </div>
                </div>
                <div class='editor__auto-translation bottom-separator'>
                    <v-btn
                        small
                        text
                        color='#93BD20'
                        @click='automaticTranslation()'
                        class='ml-1'
                    >
                        Automatic translation <v-icon small>language</v-icon>
                    </v-btn>
                </div>
                <v-row no-gutters class="editor__row d-flex">
                    <div class="editor__language pt-5 pl-5">
                        <v-chip small dense label dark class="pl-1 pr-1 pt-0 pb-0 text-uppercase" color="#439ED5">{{languageDisplay}}</v-chip>
                    </div>
                    <v-col class="editor__input pr-5">
                        <MessagingTemplateForm
                            :messageTemplate="messageTemplate"
                            :placeholders="templateTags"
                            @templateChange='touched=true'
                        />
                    </v-col>
                </v-row>
            </div>
        </div>
        <v-dialog
            v-model='resetDialogVisibility'
            width='400px'
            :disabled='isMessageTemplateLoading'
        >
            <v-card>
                <v-card-title class="text-h5">
                    Attention
                </v-card-title>

                <v-card-text>
                    This will overwrite any previously saved messages.
                </v-card-text>

                <v-card-actions>
                    <v-row>
                        <v-col>
                            <v-btn
                                color="darken-1"
                                text
                                @click="resetDialogVisibility = false"
                                block
                            >
                                Cancel
                            </v-btn>
                        </v-col>
                        <v-col>
                            <v-btn
                                color="green darken-1"
                                text
                                @click="resetToDefault()"
                                block
                            >
                                Continue
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog
            v-model='duplicateSaveAttentionDialogVisibility'
            width='400px'
            :disabled='isMessageTemplateLoading'
        >
            <v-card>
                <v-card-title class="text-h5">
                    Attention
                </v-card-title>

                <v-card-text>
                    Message must be saved before duplication.
                </v-card-text>

                <v-card-actions>
                    <v-row>
                        <v-col>
                            <v-btn
                                color="darken-1"
                                text
                                block
                                @click="duplicateSaveAttentionDialogVisibility = false"
                            >
                                Cancel
                            </v-btn>
                        </v-col>
                        <v-col>
                            <v-btn
                                color="green darken-1"
                                text
                                block
                                @click='saveAndDuplicate()'
                            >
                                Continue
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog
            v-model='duplicateDialogVisibility'
            width='800px'
        >
            <v-card>
                <v-card-title>Duplicate to another location</v-card-title>
                <v-card-subtitle>Search for locations, then select to add.</v-card-subtitle>
                <v-card-text>
                    <div class='d-flex align-center'>
                        <store-search-new
                            :airport="duplicateDialog.currentAirport"
                            :waypoint="duplicateDialog.currentWaypoint"
                            @airport-change="duplicateDialog.currentAirport = $event"
                            @waypoint-change="duplicateDialog.currentWaypoint = $event"
                        />
                        <v-btn
                            class='ml-3'
                            :disabled='!duplicateDialog.currentWaypoint'
                            small
                            color='primary'
                            @click='addWaypointToDuplicate()'
                        >
                            Add
                        </v-btn>
                    </div>
                    <div class='mt-5'>
                        <line-item class='pa-5'>
                            <v-expansion-panels
                                v-model='duplicateDialog.selectedItemsExpanded'
                            >
                                <v-expansion-panel>
                                    <v-expansion-panel-header><span>Selected waypoints <v-chip small color='secondary' outlined>{{duplicateDialog.items.length}}</v-chip></span> </v-expansion-panel-header>
                                    <v-expansion-panel-content>
                                        <v-list>
                                            <v-list-item v-for='waypoint of duplicateDialog.items'>
                                                {{waypointLabelById(waypoint)}}
                                            </v-list-item>
                                        </v-list>
                                    </v-expansion-panel-content>
                                </v-expansion-panel>

                            </v-expansion-panels>
                        </line-item>
                    </div>
                </v-card-text>
                <v-card-actions>
                    <v-row>
                        <v-col>
                            <v-btn
                                color="darken-1"
                                text
                                block
                                @click='cancelDuplicate()'
                            >
                                Cancel
                            </v-btn>
                        </v-col>
                        <v-col>
                            <v-btn
                                color="green darken-1"
                                text
                                :disabled='duplicateDialog.items.length === 0'
                                @click='duplicate()'
                            >
                                Duplicate to selected waypoints
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog
            v-model='duplicateSuccessfullyDialog.visibility'
            width='400px'
        >
            <v-card>
                <v-card-title>Success!</v-card-title>
                <v-card-subtitle>Duplicated to these locations:</v-card-subtitle>
                <v-card-text>
                    <v-list>
                        <v-list-item v-for='waypoint of duplicateSuccessfullyDialog.items'>
                            <div class='d-flex justify-space-between align-center' style='width: 100%'>
                                <div>{{waypointLabelById(waypoint)}}</div>
                                <div><v-icon color='primary'>check</v-icon></div>
                            </div>
                        </v-list-item>
                    </v-list>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import ContextTitle from "components/context_title.vue";
import clone from "clone";
import _ from 'lodash';

import MessagingTemplateForm from "components/messaging/MessagingForm.vue";
import {mapActions, mapGetters} from "vuex";
import {OperationStatus} from "helpers/common";
import metadata from "mixins/messagingMetadata";
import EnvSelectorDropdown from 'components/EnvSelectorDropdown';
import StoreSearchNew from 'components/store_search_new';
import LineItem from 'components/line_item';
import PlatformLabel from 'components/messaging/platform_label';

export default {
    name: "TemplateEdit",
    components: {
        ContextTitle,
        MessagingTemplateForm,
        EnvSelectorDropdown,
        StoreSearchNew,
        LineItem,
        PlatformLabel,
    },
    mounted() {
        this.getAirports();

        if (this.templateMetadataStatus === OperationStatus.Unknown || this.templateMetadataStatus === OperationStatus.Failed) {
            this.getMessageTemplateMetaData();
        }

        if (this.templateTagsStatus === OperationStatus.Unknown || this.templateTagsStatus === OperationStatus.Failed) {
            this.getCustomerSMSMessageTemplateTags();
        }

        if (this.customerSmsTemplatesStatus === OperationStatus.Unknown
            || this.customerSmsTemplatesStatus === OperationStatus.Failed
            || !this.templateById(this.id).TemplateID
        ) {
            this.getCustomerSMSMessageTemplates(this.waypointId);
        }
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.previousRoute = from
        })
    },
    mixins: [metadata],
    data() {
        return {
            previousRoute: null,
            chipAttrs: {
                outlined:true,
                label: true,
                class: "ma-1",
                color: "black",
            },
            environment: 'stage',
            resetDialogVisibility: false,
            duplicateSaveAttentionDialogVisibility: false,
            touched: false,
            duplicateDialogVisibility: false,
            duplicateDialog: {
                currentAirport: null,
                currentWaypoint: null,
                items: [],
                selectedItemsExpanded: false,
            },
            duplicateSuccessfullyDialog: {
                visibility: false,
                items: [],
            }
        }
    },
    methods: {
        ...mapActions("MessagingStore", [
            "getMessageTemplateMetaData",
            "getCustomerSMSMessageTemplates",
            "getCustomerSMSMessageTemplateTags",
            "editTemplate",
            "resetTemplate"
        ]),
        ...mapActions("AirportStore", ["getAirports"]),
        navigateBackToMessaging() {
            this.$router.push(this.previousRoute && this.previousRoute.name === 'messaging'
                ? this.previousRoute
                : {name: 'messaging'}
            );
        },
        async save() {
            const clonedTemplate = clone(this.messageTemplate);
            const { isSucceeded, templateID } = await this.editTemplate({waypointId: this.waypointId, template: clonedTemplate, redirectAfterCreate: true});
            if (isSucceeded && templateID !== this.id) {
                await this.$router.push({
                    name: 'messaging-edit',
                    params: {
                        waypointID: this.waypointId,
                        templateID: templateID,
                    },
                });
                this.touched = false;
            }
        },
        cancel() {
            this.navigateBackToMessaging();
        },
        waypointLabelById(id) {
            const waypoint = this.locations.find(x => x.waypointID == id);
            return waypoint ? `${waypoint.name} - ${waypoint.terminal}, ${waypoint.airportIdent}` : `Unknown waypoint with id ${id}`;
        },
        async resetToDefault() {
            await this.resetTemplate({
                templateID: this.id,
                languageCode: this.messageTemplate ? this.messageTemplate.LanguageCode : null,
                waypointId: this.waypointId
            });
            await this.getCustomerSMSMessageTemplates(this.waypointId);
            this.resetDialogVisibility = false;
        },
        delete() {

        },
        openDuplicateDialog() {
            if (this.touched) {
                this.duplicateSaveAttentionDialogVisibility = true;
            } else {
                this.duplicateDialogVisibility = true;
            }
        },
        addWaypointToDuplicate() {
            this.duplicateDialog.items = [...new Set([...this.duplicateDialog.items, this.duplicateDialog.currentWaypoint])]
        },
        async saveAndDuplicate() {
            await this.save();
            this.duplicateSaveAttentionDialogVisibility = false;
            this.duplicateDialogVisibility = true;
        },
        duplicate() {
            this.duplicateDialogVisibility = false;
            console.error(`Duplicate ${this.id}, templateBody: ${this.messageTemplate.LanguageCode}, to: ${JSON.stringify(this.duplicateDialog.items)}`)
            this.duplicateSuccessfullyDialog.visibility = true;
            this.duplicateSuccessfullyDialog.items = this.duplicateDialog.items;

        },
        cancelDuplicate() {
            this.duplicateDialog.items = [];
            this.duplicateDialog.currentWaypoint = null;
            this.duplicateDialog.currentAirport = null;
            this.duplicateDialogVisibility = false;
        },
        automaticTranslation() {
            console.error('Automatic translation of ${this.id}')
        }
    },
    computed: {
        ...mapGetters("MessagingStore", [
            "templateById",
            "eventLabelById",
            "platformLabelById",
            "orderTypeLabelById",
            "customerSmsTemplatesStatus",
            "templateMetadataStatus",
            "templateTagsStatus",
            "templateTags",
            "templateEditStatus",
            "templateDeleteStatus",
        ]),
        ...mapGetters("AirportStore", ["locations"]),
        messageTemplate() {
            return {
                ...this.templateById(this.id)
            };
        },
        isMessageTemplateLoading() {
            const intersection = _.intersection([OperationStatus.InProgress, OperationStatus.Unknown],
                [this.customerSmsTemplatesStatus, this.templateTagsStatus]
            );
            return intersection.length > 0
                || this.templateEditStatus(this.id) === OperationStatus.InProgress
                || this.templateDeleteStatus(this.id) === OperationStatus.InProgress;
        },
        titleBarActions() {
            const cancel = {
                type: "cancel",
                display: "Cancel",
                run: this.cancel,
            };

            const save = {
                type: "proceed",
                display: "Save",
                run: this.save,
            };

            if (this.isMessageTemplateLoading) {
                return [cancel];
            } else {
                return [cancel, save];
            }
        },
        id() {
            return this.$route.params.templateID;
        },
        waypointId() {
            return this.$route.params.waypointID;
        },
        waypointLabel() {
            return this.waypointLabelById(this.waypointId);
        },
        platformTypeOptions() {
            return Object.entries(this.platformTypes).map(([id, text]) => ({
                id,
                text,
                isSelected: this.messageTemplate.PlatformTypeID === 0 || this.messageTemplate.PlatformTypeID == id,
            }));
        },
        orderTypeOptions() {
            return Object.entries(this.orderTypes).map(([id, text]) => ({
                id,
                text,
                isSelected: this.messageTemplate.OrderTypeID === 0 || this.messageTemplate.OrderTypeID == id,
            }));
        },
        languageDisplay() {
            let languageNames = new Intl.DisplayNames([this.messageTemplate.LanguageCode], {
                type: "language",
            });
            return languageNames.of(this.messageTemplate.LanguageCode)
        }
    },
};
</script>

<style lang="scss" scoped>
    @import "../../scss/variables";
    @import "../../scss/mixins";

    .message-edit-wrapper {
        padding-left: 30px;
        padding-right: 30px;
        padding-top: 25px;
        max-width: 880px;
    }

    .message-edit-header {
        background: white;
        border: 1px solid $grey_light_1;
        border-radius: 4px;
        &__icon {
            color: #84B7D3;
        }
        &__title {
            padding-left: 10px;
            font-size: fz(venti);
        }
        &__subtitle {
            font-size: fz(sm);
            font-weight: bold;
            width: 132px;
            padding-left: 20px;
        }
    }
    .message-edit-waypoint{
        padding-bottom: 14px;

        &__title {
            font-size: fz(venti);
            font-weight: 500;
            line-height: 23px;
            color: $grey_dark_1;
        }
    }
    .bottom-separator {
        border-bottom: 1px solid #e4e4e4;
    }
    .editor {
        background: white;
        border: 1px solid $grey_light_1;
        border-radius: 4px;
        margin-top: 20px;

        &__header {
            padding: 15px 20px;
            font-size: fz(sm);
            line-height: 16px;
            text-transform: uppercase;
            color: $grey_dark_1;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
        &__language {
            width: 132px;
        }
        &__auto-translation {
            padding-top: 3px;
            padding-bottom: 3px;
        }
    }
</style>
