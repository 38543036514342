<template>
    <v-form class="template-form fill-height pt-2">
        <div class="form-input large block-xs--xs flex-grow-0">
            <template-editor
                label="Template"
                :placeholders="placeholders"
                v-model="messageTemplate.MessageBodyTemplate"
                @input='$emit("templateChange")'
            />
        </div>
    </v-form>
</template>

<script>
import FormInput from "components/cleverly/FormInput.vue";
import LineItem from "components/line_item.vue";
import SelectInput from "components/cleverly/SelectInput.vue";
import DropdownFormInput from "components/DropdownFormInput.vue";
import TextBox from "components/cleverly/TextBox.vue";

import TemplateEditor from "@/components/template_editor";
import {mapGetters} from "vuex";
import TextField from "components/form/TextField";
import TextFieldRow from "components/form/TextFieldRow";

export default {
    name: "MessagingTemplateForm",
    components: {
        FormInput,
        LineItem,
        SelectInput,
        DropdownFormInput,
        TextBox,
        TemplateEditor,
        TextField,
        TextFieldRow
    },
    props: {
        messageTemplate: {
            type: Object,
            default: () => ({
                MessageBodyTemplate: null,
            }),
        },
        placeholders: {
            type: Array,
            required: true,
        }
    },
    data() {
        return {
            textFieldRowProps: {
                readonly: true,
                dense: true,
                md: 6,
                lg: 4,
                sm: 12,
            }
        };
    },
    computed: {
        ...mapGetters("MessagingStore", [
            "eventLabelById",
            "platformLabelById",
            "orderTypeLabelById",
        ]),
    }
};
</script>

<style lang="scss">
@import "~scss/variables";
@import "~scss/mixins";

.template-form {
    textarea {
        @include below(md) {
            width: 100%;
        }
    }

    input {
        @include below(xs) {
            width: 100%;
        }
    }

    .form-input {
        input {
            @include above(md) {
                width: 331px;
            }
        }
    }

    .vselect-input {
        input {
            @include above(md) {
                width: 305px;
            }

            @include below(xs) {
                width: 100%;
            }
        }
    }

    .select-input {
        select {
            @include above(md) {
                width: 331px;
            }
        }
    }

    .block-label > label {
        @include roboto-bold;
        display: block;
        font-size: fz(sm);
        color: $grey_mid_1;
        margin: spacing(xs) 0;
        width: auto;
    }

    & > .line-item {
        padding: 0 30px;
        margin-right: 0;
    }

    .label-offset {
        margin-left: 155px;

        &.line-item {
            margin-right: -30px;

            &:before {
                display: none;
            }
        }
    }

    .label--template-form {
        width: 210px;
    }
}
</style>
